export default {

  name: "Mahjouba Bouchouf",
  title: "Web Developer",
  email: "mahjoubabouchouf@gmail.com",
  phone: "+218920160559",
  location: "Tripoli, Libya",
  cvLink: "https://docs.google.com/document/d/1-hSLNAZG91fxurx6SQtnlSsU_9M27NL2kakECqhWtIk/export?format=pdf",
  slug : "Hi, My name is Mahjouba...",
  about:"As they say, The journey of a thousand miles begins with a single step. Here’s a look at my journey!\"",
  about_me: "I am a full-stack developer with a passion for creating beautiful and functional websites. I have experience with a variety of technologies including HTML, CSS, JavaScript, and Vue.js. I am always looking for new opportunities to learn and grow as a developer.",
  skills: [
    "Html",
    "Css",
    "JavaScript",
    "Botstrap",
    "Tailwind",
    "Vue.js",
    "PHP",
    "Laravel",
    "Python",
    "Django",
    "MongoDB",
    "MySQL",
    "RESTful APIs",
    "Docker",
    "AWS",
    "Git",
    "Agile Methodologies",
  ],
  experience: [
    {
      title: "Full Stack Developer",
      company: "TechInnovate Solutions",
      date: "June 2021 - Present",
      description: "Develop and maintain scalable web applications using React, Node.js, and PostgreSQL. Implement RESTful APIs and integrate third-party services. Collaborate with cross-functional teams to deliver high-quality software solutions.",
    },
    {
      title: "Junior Web Developer",
      company: "Digital Creations Agency",
      date: "August 2019 - May 2021",
      description: "Assisted in the development of responsive websites using HTML, CSS, and JavaScript. Worked on PHP-based CMS platforms and contributed to the maintenance of existing web applications.",
    },
  ],
  projects: [
    {
      id: 1,
      title: "Multivendor E-Commerce Platform",
      description: "Developed a multivendor e-commerce platform that enables multiple vendors to manage and sell their products through a single platform. Integrated the Plutu payment gateway for secure online payments, allowing vendors to offer seamless and reliable payment options for their customers.\n",
      technologies: [
        {name:"figma", image:"https://cdn.simpleicons.org/figma/F24F7E"},
        { name: "Laravel", image: "https://cdn.simpleicons.org/laravel/FF2D20" },
        { name: "Vue.js", image: "https://cdn.simpleicons.org/vue.js/4FC08D" },
        { name: "Tailwind CSS", image: "https://cdn.simpleicons.org/tailwindcss/06B6D4" },
        { name: "MySQL", image: "https://cdn.simpleicons.org/mysql/4479A1" },
        {name:"plutu", image: require("@/assets/icons/plutu.png")},
      ],
      projectLink: "https://github.com/manomano500/bazar-backend/",
      image: [
        require("@/assets/projects/bazar_admin_dashboard.png"),
        require("@/assets/projects/bazar_profile.png")
      ]
    },
    {
      id:4,
      title: "Library Web App",
      description: "Built a web application that enables students to access and download PDF versions of books. Utilized Bootstrap for responsive design, PrimeFaces for rich UI components, and Java EE for backend functionality.",
      technologies: [
        // Frontend Development
        { name: "HTML5", image: "https://cdn.simpleicons.org/html5/E34F26" },
        { name: "CSS3", image: "https://cdn.simpleicons.org/css3/1572B6" },
        { name: "JavaScript", image: "https://cdn.simpleicons.org/javascript/F7DF1E" },
        { name: "PrimeFaces", image: "https://cdn.simpleicons.org/primefaces/000000" },
        {name:"java", image: require("@/assets/java.png")},
        // Add this line for PrimeFaces
        // Backend Development
        { name: "MySQL", image: "https://cdn.simpleicons.org/mysql/4479A1" },
    ],

        projectLink: "https://github.com/manomano500/ClassBookFinder.git/",
      image:[
        require("@/assets/projects/library_home.png"),
        require("@/assets/projects/library_list.png"),
        require("@/assets/projects/library_subj.png"),
      ]

    },
    {
      id:2,
      title: "FluencyCheck",
      description: "Developed a web application that tests users' English language skills through a series of interactive quizzes. The app provides instant feedback on users' answers and tracks their progress over time.",
      technologies: [
        { name: "HTML5", image: "https://cdn.simpleicons.org/html5/E34F26" },
        { name: "CSS3", image: "https://cdn.simpleicons.org/css3/1572B6" },
        { name: "JavaScript", image: "https://cdn.simpleicons.org/javascript/F7DF1E" },

        { name: "Vue.js", image: "https://cdn.simpleicons.org/vue.js/4FC08D" },
        { name: "Tailwind CSS", image: "https://cdn.simpleicons.org/tailwindcss/06B6D4" },
      ],
      projectLink: "https://test-your-english.jouba.me/",
      image: [

        require("@/assets/projects/fluancy_result.png"),
      ], // Add the image URL or path
    },
    {
      id: 5,
      title: "Planets Information ",
      description: "Developed a user-friendly web interface that displays detailed information about planets and their characteristics within the solar system. The interface is visually engaging, fully responsive, and designed to enhance the user's understanding of planetary features.",
      technologies: [
        { name: "HTML5", image: "https://cdn.simpleicons.org/html5/E34F26" },
        { name: "CSS3", image: "https://cdn.simpleicons.org/css3/1572B6" },
        { name: "JavaScript", image: "https://cdn.simpleicons.org/javascript/F7DF1E" },
        { name: "Vue.js", image: "https://cdn.simpleicons.org/vue.js/4FC08D" },
        { name: "Tailwind CSS", image: "https://cdn.simpleicons.org/tailwindcss/06B6D4" },
      ],
      projectLink: "https://planetarium.jouba.me//",
      image: [
        require("@/assets/projects/planetes_home.png")
      ]
    }


    ,


  ],

  socials: [
    {name: "figma", link: "https://www.figma.com/@mahjouba", icon: "https://cdn.simpleicons.org/figma/FFFFFF"},
    { name: "LinkedIn", link: "https://www.linkedin.com/in/mahjouba-bouchouf/", icon: require("@/assets/icons/linkedIn.svg") },
    { name: "GitHub", link: "https://www.github.com/manomano500", icon: require("@/assets/icons/github.svg") },
    { name: "X", link: "https://x.com/mahjouba26", icon: require("@/assets/icons/x.svg") },
    {name:"gmail", link: "mailto:majoubabouchouf@gmail.com", icon: "https://cdn.simpleicons.org/gmail/FFFFFF"},
  ],
  technologies: [
    // Frontend Development
    { name: "HTML5", image: "https://cdn.simpleicons.org/html5/E34F26" },
    { name: "CSS3", image: "https://cdn.simpleicons.org/css3/1572B6" },
    { name: "JavaScript", image: "https://cdn.simpleicons.org/javascript/F7DF1E" },
    { name: "Bootstrap", image: "https://cdn.simpleicons.org/bootstrap/563D7C" },
    { name: "Tailwind CSS", image: "https://cdn.simpleicons.org/tailwindcss/06B6D4" },
    { name: "Vue.js", image: "https://cdn.simpleicons.org/vue.js/4FC08D" },

    // Backend Development
    { name: "PHP", image: "https://cdn.simpleicons.org/php/777BB4" },
    { name: "Laravel", image: "https://cdn.simpleicons.org/laravel/FF2D20" },
    { name: "RESTful API", image: require("@/assets/rest_api.png") },
    { name: "Python", image: "https://cdn.simpleicons.org/python/3776AB" },
    {name:"java", image: require("@/assets/java.png")},
    {name: "MySQL", image: "https://cdn.simpleicons.org/mysql/4479A1"},
    {name:"PostMan", image: "https://cdn.simpleicons.org/postman/FF6C37"},
    // Placeholder for RESTful API

    // Mobile Development
    { name: "Flutter", image: "https://cdn.simpleicons.org/flutter/02569B" },
    { name: "Dart", image: "https://cdn.simpleicons.org/dart/0175C2" },

    // Version Control
    { name: "Git", image: "https://cdn.simpleicons.org/git/F05032" },
    { name: "GitHub", image: "https://cdn.simpleicons.org/github/181717" },

    // Design Tools
    { name: "Figma", image: "https://cdn.simpleicons.org/figma/F24F7E" },
 ]

};

