import { createStore } from "vuex";
import data from "@/data/data";

const store = createStore({
  state: {
    data



  },
  getters: {
    getDepartments: (state) => state.departments, // Getter to access departments

  },
  mutations: {

  },
  actions: {

  },
  modules: {},
});
export default store;
