<template>
  <nav class="bg-white">
    <div class="max-w-6xl mx-auto px-4">
      <div class="flex justify-between">
        <div class="flex space-x-7">
          <div>
            <router-link to="/" class="nav-link flex items-center py-4 px-2">
              <span class="font-semibold text-gray-500 text-lg">{{ data.name }}</span>
            </router-link>
          </div>
        </div>
        <div class="hidden md:flex items-center space-x-1">
          <a href="#home" class="nav-link" :class="{ 'nav-link-active': $route.hash === '#home' }">
            Home
          </a>
          <a href="#about" class="nav-link" :class="{ 'nav-link-active': $route.hash === '#about' }">
            About
          </a>
          <a href="#projects" class="nav-link" :class="{ 'nav-link-active': $route.hash === '#projects' }">
            Projects
          </a>
          <a href="#contact" class="nav-link" :class="{ 'nav-link-active': $route.hash === '#contact' }">
            Contact
          </a>
        </div>

        <div class="md:hidden flex items-center">
          <button @click="toggleMobileMenu" class="outline-none mobile-menu-button">
            <svg class="w-6 h-6 text-gray-500 hover:text-purple-500"
                 x-show="!showMenu"
                 fill="none"
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke-width="2"
                 viewBox="0 0 24 24"
                 stroke="currentColor">
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div :class="{ hidden: !showMenu }" class="mobile-menu">
      <ul>
        <li><router-link to="/#home" class="block text-sm px-2 py-4 hover:bg-purple-500 transition hover:text-white duration-300">Home</router-link></li>
        <li><router-link to="/#about" class="block text-sm px-2 py-4 hover:bg-purple-500 transition hover:text-white duration-300">About</router-link></li>
        <li><router-link to="/#projects" class="block text-sm px-2 py-4 hover:bg-purple-500 transition hover:text-white duration-300">Projects</router-link></li>
        <li><router-link to="/#contact" class="block text-sm px-2 py-4 hover:bg-purple-500 transition hover:text-white duration-300">Contact</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue";
import store from "@/store";

const data = computed(() => store.getters.getData);
const showMenu = ref(false);

const toggleMobileMenu = () => {
  showMenu.value = !showMenu.value;
};

const handleClickOutside = (event) => {
  const menu = document.querySelector(".mobile-menu");
  const button = document.querySelector(".mobile-menu-button");

  if (menu && !menu.contains(event.target) && !button.contains(event.target)) {
    showMenu.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style>
/* Add styles as needed */
</style>
